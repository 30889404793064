<template>
  <div class="main">
    <van-nav-bar
        title="下发检查"
        left-text="返回"
        right-text=""
        left-arrow
        @click-left="onClickLeft"
     />
    <div class="main">
      <div class="jian"></div>
      <div class="block1">
        <van-row  type="flex" justify="left" gutter="1">
          <van-col span="7" ><div class="zuo">被检查单位</div></van-col>
          <van-col span="17"><div class="you">{{data.storeName}}</div></van-col>
        </van-row>
        <van-row  type="flex" justify="left" gutter="1">
          <van-col span="7" ><div class="zuo">企业负责人</div></van-col>
          <van-col span="17"><div class="you">{{data.contact}}</div></van-col>
        </van-row>
        <van-row  type="flex" justify="left" gutter="1">
          <van-col span="7" ><div class="zuo">联系电话</div></van-col>
          <van-col span="17"><div class="you">{{data.phone}}</div></van-col>
        </van-row>
        <van-row  type="flex" justify="left" gutter="1">
          <van-col span="7" ><div class="zuo">单位地址</div></van-col>
          <van-col span="17"><div class="you">{{data.address}}</div></van-col>
        </van-row>
      </div>
      <div class="jian"></div>
      <div class="block2">
        <van-row  type="flex" justify="center" >
          <van-col span="12" ><div class="chu" style="line-height: 200%">预警类别</div></van-col>
          <van-col span="12"><div class="chu" style="line-height: 200%">检查类型</div></van-col>
        </van-row>
        <van-row  type="flex" justify="center" >
          <van-col span="12" ><div class="">{{data.remark}}</div></van-col>
          <van-col span="12"><div class="">{{ctype(data.type)}}</div></van-col>
        </van-row>
      </div>
      <div class="jian"></div>
      <div class="block3" style="padding-right: 1rem">

        <van-row  type="flex" justify="end" >
          <van-col span="5" ><div class="chu" style="line-height: 200%">检查时间</div></van-col>
          <van-col span="19">
<!--            <div v-if="!data.startDate" @click="showBeginDate" style="line-height: 200%;text-align: right; padding-right: 10px;color: #333333">请选择时间<van-icon name="arrow" /></div>-->

            <div style="text-align: right" >
              <div style="line-height: 200%" @click="showBeginDate(1)">{{data.startDate?data.startDate:'开始时间'}}</div>
              <div style="line-height: 200%" @click="showBeginDate(2)">{{data.endDate?data.endDate:'结束时间'}}</div>
            </div>
          </van-col>

        </van-row>
        <van-row  type="flex" justify="end" >
          <van-col span="5" ><div class="chu" style="line-height: 200%">检查人员</div></van-col>
          <van-col span="19" @click="showMemberPup">
            <div class="mcss" style="" v-if="membered.length==0">请添加检查人员<van-icon name="arrow" /></div>
            <div class="mcss" v-if="membered.length>0"><span v-for="(x,index) in membered" :key="index">{{x.trueName}}</span></div>
          </van-col>
        </van-row>

      </div>
      <div class="jian"></div>
    </div>
    <div style="margin-top: 2rem"><van-button plain hairline type="info" style="width: 50vw" @click="okConfirm">下发检查</van-button></div>
    <van-popup v-model="showPickerDate" position="bottom">
      <van-datetime-picker v-model="currentDate" type="datetime" @cancel="onCancelDate" @confirm="onConfirmDate" />
    </van-popup>
    <van-popup v-model="showMember" :style="{ height: '50vh',width:'95%' }" closeable round>
      <div style="padding: 2rem" class="mlist">
        <div >
          <van-row  type="flex" justify="center" v-for="(x,index) in member" :key="index">
            <van-col span="19" ><div style="text-align: left">{{x.trueName}}</div></van-col>
            <van-col span="5"><div class=""><van-checkbox v-model="x.active">选择</van-checkbox></div></van-col>
          </van-row>
        </div>

      </div>
      <div ><van-button plain hairline type="info" style="width: 30vw" @click="okMember">确定</van-button></div>
    </van-popup>
  </div>

</template>

<script>
import { getTaskDetail,getXiafa,getType,getMember } from '@/api/get'
import Vue from 'vue';
import { NavBar,Col, Row,Icon,Cell,Popup,DatetimePicker,Button,Checkbox,Toast,Dialog } from 'vant';
Vue.use(Col);
Vue.use(Row);
Vue.use(NavBar);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(Popup);
Vue.use(DatetimePicker);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Toast);
Vue.use(Dialog);
export default {
  name: "xiafa",
  data(){
    return{
      sjid:"1",
      member:[],
      membered:[],
      data:{},
      id:0,
      showPickerDate: false,
        currentDate: new Date(),
        showMember: false,
    }},
  mounted() {
    this.id=this.$route.query.id
    this.loadData()
    //this.queue(this.$route.meta.id,this.$route.meta.title,this.$route.path)
  },
  methods:{
    timeFormat(time) { // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      let hh = time.getHours();
      let mm = time.getMinutes();
      return year + '-' + month + '-' + day + ' '+hh+':'+mm+':00'
    },
    okMember(){
      this.showMember=false
      this.membered = this.member.filter(item => item.active==true)
    },
    okConfirm(){
      Dialog.confirm({
        title: '是否确认下发',
        message: '',
      })
          .then(() => {
           this.ok()
          })
          .catch(() => {
            // on cancel
          });
    },
    ok(){
      var arr = this.membered.map(function (item) {
        return item.id;
      })
      if(arr.length==0){
        Toast("请选人员")
        return
      }
      getXiafa({id:this.id,memberIdList:arr}).then(res => {
        if (res.data.code === 200){
          this.$router.push({ path: '/home', query: {}})
        }
      })
    },
    ctype(t){
      return getType(t)
    },
    loadMember(){
      let regulatoryId=localStorage.storeId
      getMember({regulatoryId:regulatoryId}).then(res => {
        if (res.data.code === 200){
          this.member=res.data.data
        }
      })
    },
    loadData(){
      getTaskDetail({id:this.id}).then(res => {
        if (res.data.code === 200){
          this.data=res.data.data

        }
      })

      let regulatoryId=localStorage.storeId
      getMember({regulatoryId:regulatoryId}).then(res => {
        if (res.data.code === 200){
          this.member=res.data.data
        }
      })

    },
    onConfirmDate (val) {
      val=this.timeFormat(val)
     if(this.sjid==1){
       this.data.startDate=val
     }
      if(this.sjid==2){
        this.data.endDate=val
      }
      this.onCancelDate()
    },
    onCancelDate () {
      this.showPickerDate = false
    },
    onClickLeft() {
      this.$router.back()
    },
    showBeginDate (id) {
      if(this.data.type!=10){
        return
      }
      this.sjid=id
      this.showPickerDate = true
    },
    showMemberPup () {
      this.loadMember()
      this.showMember = true
    },
  }
}
</script>

<style scoped lang="css" src="./assets/index.css" />